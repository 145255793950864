import {onFind} from "@elements/init-modules-in-scope";
import {removeClass,addClass,on,findAllIn, findIn, closest} from '@elements/dom-utils';

const selectors = {
    base: '.js-input-length',
    input: '.js-input-length__input',
    error: '.js-input-length__error',
    btn: '.js-input-length__btn'
};

export function init() {
    onFind(selectors.base, function (baseElement) {
        let items = findAllIn(selectors.input, baseElement);

        items.forEach((item, index) => {
            let input = item;
            let parent = closest(selectors.base, input);
            let errorMessage = findIn(selectors.error, parent);
            let btn = findIn(selectors.btn, parent);

            on('keyup', function () {
                setTimeout(function () {
                    if (input.value === "" || input.value.length > 30){
                        addClass("is-invalid", input);
                        removeClass("is-valid", input);
                        btn.setAttribute('disabled', true);
                        errorMessage.removeAttribute('hidden');
                    }else{
                        removeClass("is-invalid", input);
                        addClass("is-valid", input);
                        btn.removeAttribute('disabled');
                        errorMessage.setAttribute('hidden', true);
                    }
                }, 200);
            }, input);
        });
    });
}
