import { startStimulusApp } from '@symfony/stimulus-bridge';
import {onFind} from "@elements/init-modules-in-scope";

export const app = startStimulusApp(require.context(
    '@symfony/stimulus-bridge/lazy-controller-loader!../vue/views',
    true,
    /\.(j|t)sx?$/
));

import { registerVueControllerComponents } from '@symfony/ux-vue';
registerVueControllerComponents(require.context('../vue/views', true, /\.vue$/, 'lazy'));

// NEEDED IF YOU USE PINIA

// import {createPinia} from 'pinia';
// document.addEventListener('vue:before-mount', (event) => {
//     const {
//         app,
//     } = event.detail;
//
//     const pinia = createPinia()
//     app.use(pinia);
// });

/*document.getElementsByClassName('js-main-nav-toggle')[0].addEventListener('click', function() {
    this.classList.toggle('open');
});*/

import * as toc from '@elements/toc';
toc.init({linkClass: 'visually-hidden-focusable'});

import * as sideNav from './side-nav';
sideNav.init();

import * as dynamicModal from './dynamic-modal';
dynamicModal.init();

import * as autocomplete from './autocomplete';
autocomplete.init();

import * as sideItems from './side-items';
sideItems.init();

import * as skipLinks from '@elements/skip-links';
skipLinks.init();

import * as modal from './modal';
modal.init();

import * as ajaxTab from "./ajax-tab";
ajaxTab.init();

import * as redirectForm from './redirect-form';
redirectForm.init();

import * as lazyImg from '@elements/lazy-img';
lazyImg.init();

import * as header from './header';
header.init();

import * as responsiveTable from './responsive-table';
responsiveTable.init();

import * as tab from './tab';
tab.init();

import Collapse from 'bootstrap/js/dist/collapse';

import * as dropdown from './dropdown';
dropdown.init();

import * as slider from './slider';
slider.init();

import * as hotspots from './hotspots';
hotspots.init();

import * as actionChanger from "@elements/action-changer";
actionChanger.init();

import * as videoPlay from './video-play';
videoPlay.init();

import * as inputLength from './input-length';
inputLength.init();

import * as fileUpload from './file-upload';
fileUpload.init();

import * as spriteIcons from './sprite-icons';

// Todo: Add common JS that is shared between all page types here

// import * as renderTemplate from './render-template';
// renderTemplate.init();

import * as contentVisibility from './content-visibility';
contentVisibility.init();

import * as hashCash from './hashcash';
hashCash.init();

import * as ajaxFormWithValidation from './ajax-form-with-validation';
ajaxFormWithValidation.init();

import * as formValidation from '@elements/form-validation';
formValidation.init();

import * as reloadForm from "../libs/@elements/reload-form";
reloadForm.init();

import * as ajaxForm from '../libs/@elements/ajax-form';
ajaxForm.init();

import * as servicePopup from './service-popup';
servicePopup.init();

import * as categoryList from './category-list';
categoryList.init();

import * as anchorNav from '@elements/anchor-nav/bootstrap-5';
anchorNav.init({
        renderItem: function (text, anchor) {
            return '<li class="nav-item anchor-nav__item list-inline-item scroll-item"><a class="anchor-nav__link js-smoothscroll__link" data-scroll-offset="150" href="#' + anchor + '"><span class="anchor-nav__title">' + text + '</span></a></li>';
        },
        smoothScroll: true,
        scrollSpy: !!document.getElementsByClassName('js-anchor-nav__item'),
        scrollSpyTarget: '.js-anchor-nav'
    }, {
        list: '.js-anchor-nav__list',
        item: '.js-anchor-nav__item'
    },
);

import {init as initAlertNotification} from '@elements/alert-notification';
initAlertNotification({
    closable: true
});

import Alert from 'bootstrap/js/dist/alert';
onFind('.alert',function (element) {
    new Alert(element);
});

import * as numberSpinner from '@elements/number-spinner';
numberSpinner.init();

import * as addToCart from './add-to-cart';
addToCart.init();

import * as tracking from '@elements/tracking';
tracking.initGtmEventTracking({debug: true});